
import Vue from 'vue'
import global from '~/mixins/global'

Vue.mixin(global)

export default {
  head() {
    const i18nHead = this.$nuxtI18nHead({
      addDirAttribute: true,
      addSeoAttributes: true,
    })

    return {
      title: this.whiteLabel.title ? this.whiteLabel.title : 'Be Agent',
      titleTemplate:
        '%s - ' + (this.whiteLabel.name ? this.whiteLabel.name : 'Be Agent'),
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: ['brand-secondary'],
      },
      meta: [
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.whiteLabel.name ? this.whiteLabel.name : 'Be Agent',
        },
        {
          hid: 'author',
          name: 'author',
          content: this.whiteLabel.name ? this.whiteLabel.name : 'Be Agent',
        },
        {
          hid: 'theme-color',
          name: 'theme-color',
          content: this.whiteLabel.colors.brand.light
            ? this.whiteLabel.colors.brand.light
            : '#044AFF',
        },
        ...i18nHead.meta,
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.whiteLabel.favicon
            ? this.whiteLabel.favicon
            : '/favicon.ico',
        },
        {
          hid: 'shortcut-icon',
          rel: 'shortcut icon',
          href: this.whiteLabel.icon ? this.whiteLabel.icon : '/icon.png',
        },
        {
          hid: 'apple-touch-icon',
          rel: 'apple-touch-icon',
          href: this.whiteLabel.icon ? this.whiteLabel.icon : '/icon.png',
          sizes: '512x512',
          // }, {
          //   hid: `canonical-lang-${this.$i18n.defaultLocale}`,
          //   rel: 'canonical',
          //   href: this.$config.canonicalDomain + this.switchLocalePath(this.$i18n.defaultLocale).split('?')[0]
        },
        ...i18nHead.link,
      ],
    }
  },

  watch: {
    $route() {
      this.setFidUrl()
    },
  },

  mounted() {
    this.setFidUrl()
  },

  methods: {
    setFidUrl() {
      const query = this.$route.query

      if (!query.fid && this.$cookies.get('fid')) {
        this.$router.replace({
          query: {
            fid: this.$cookies.get('fid'),
            ...query,
          },
        })
      }
    },
  },
}
