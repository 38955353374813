// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/footer/skyline-landmarks.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/footer/skyline-generic.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/footer/skyline-landmarks@2x.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/images/footer/skyline-generic@2x.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/images/footer/skyline-london-eye.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/images/footer/skyline-london-eye@2x.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer .bottom[data-v-b6f267f0]{margin-top:115px;background-color:#f2f5f8}footer .bottom .skyline[data-v-b6f267f0]{position:absolute;width:100%;height:115px;top:-115px;left:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat,repeat-x;background-position:top,bottom;background-size:1280px 115px,1280px 26px}@media (-webkit-min-device-pixel-ratio:2),(min-device-pixel-ratio:2),(min-resolution:2dppx){footer .bottom .skyline[data-v-b6f267f0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}}footer .bottom .skyline[data-v-b6f267f0]:after{content:\"\";display:block;position:absolute;width:100px;height:100px;top:35px;left:50%;margin-left:30px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");animation:rotate-b6f267f0 65s linear infinite}@media (min-device-pixel-ratio:1.5),(-webkit-min-device-pixel-ratio:1.5),(min-resolution:1.5dppx),(-webkit-min-device-pixel-ratio:1.4375),(min-resolution:138dpi),all and (-webkit-min-device-pixel-ratio:1.5){footer .bottom .skyline[data-v-b6f267f0]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");background-size:100% 100%}}@keyframes rotate-b6f267f0{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
