import * as moment from 'moment'
import colors from 'tailwindcss/colors'
import Countries from '~/assets/static-data/countries.json'
import Currencies from '~/assets/static-data/currencies.json'

export const state = () => ({
  countries: [],
  country: null,
  currencies: [],
  currency: null,
  favorites: [],
  fid: null,
  priceDisplay: 'gross',
  visited: null,
  closed: null,
  referral: null,
  searches: [],
  unit: 'm',
  whiteLabel: {
    id: 1,
    fid: null,
    white_label: false,
    name: null,
    title: null,
    favicon: null,
    home_page_title: null,
    translations: {
      en: 'Travelor',
      he: 'טרוולאור',
    },
    colors: {
      brand: {
        // light: '#044AFF',
        light: colors.red[700],
        dark: colors.red[600],
      },
      bgBrand: {
        light: colors.gray[100],
        dark: colors.gray[900],
      },
      bgBrandAlt: {
        light: colors.white,
        dark: colors.gray[700],
      },
      textBrand: {
        light: colors.gray[600],
        dark: colors.gray[300],
      },
      textBrandAlt: {
        light: colors.gray[500],
        dark: colors.gray[200],
      },
    },
    logo: null,
    background: {
      light: '/images/default/bg-light.svg',
      dark: '/images/default/bg-dark.svg',
    },
    socials: {
      facebook: 'https://www.facebook.com/beagent.travel',
      messenger: 'https://m.me/beagent.travel',
      instagram: 'https://www.instagram.com/beagentcom',
      linkedin: 'https://www.linkedin.com/company/be-agent',
      twitter: 'https://twitter.com/BeAgentTravelor',
      whatsapp: 'https://wa.me/972525923563',
      youtube: 'https://www.youtube.com/@beagent.travel',
    },
  },
})

export const getters = {
  authCheck(state) {
    return state.auth.loggedIn
  },
  authUser(state) {
    return state.auth.user
  },
  countries(state) {
    return state.countries
  },
  country(state) {
    return state.country
  },
  currencies(state) {
    return state.currencies
  },
  currency(state) {
    return state.currency
  },
  favorites(state) {
    return state.favorites
  },
  fid(state) {
    return state.fid
  },
  priceDisplay(state) {
    return state.priceDisplay
  },
  visited(state) {
    return state.visited
  },
  closed(state) {
    return state.closed
  },
  referral(state) {
    return state.referral
  },
  searches(state) {
    return state.searches.filter(
      (item) =>
        item.params.check_in >= moment().locale('en').format('YYYY-MM-DD'),
    )
  },
  unit(state) {
    return state.unit
  },
  whiteLabel(state) {
    return state.whiteLabel
  },
}

export const actions = {
  async nuxtServerInit(
    { commit, state },
    {
      query,
      app,
      req,
      redirect,
      $axios,
      $moment,
      $config: { defaultHost, whiteLabelTld, canonicalDomain },
    },
  ) {
    const fidSaved = app.$cookies.get('fid')
    let fid = query.fid || fidSaved
    const host = req.headers.host
    let path = null

    if (host !== defaultHost) {
      if (host.endsWith(whiteLabelTld)) {
        path = `/v3/agency/${host}`
      } else {
        path = `/v3/white-label/${host}`
      }
    } else if (fid) {
      path = `/v3/agent/${fid}`
    }

    if (path) {
      await $axios
        .$get(path)
        .then((res) => {
          fid = res.data.fid
          commit('WHITE_LABEL', res.data)
        })
        .catch((e) => {
          fid = null
          commit('SET_FID', null)

          if (query.fid === 'null') {
            app.$cookies.remove('fid')
          }

          redirect(
            canonicalDomain +
              req.url
                .replace('fid=' + query.fid, '')
                .replace('?&', '?')
                .replace('&&', '&'),
          )
        })
    }

    if (process.server) {
      if (state.country == null) {
        const country =
          req.headers['cf-ipcountry'] ||
          req.headers['x-vercel-ip-country'] ||
          'US'
        commit('SET_UNIT', 'm')
        commit('SET_COUNTRY', country)
        commit('SET_COUNTRIES', Countries)

        if (state.currency == null) {
          const defaultCurrency = Countries.find(
            (c) => c.code === country,
          ).default_currency
          const currency = Currencies.find((c) => c.code === defaultCurrency)
          const code = currency ? currency.code : 'USD'
          commit('SET_CURRENCY', code)
          commit('SET_CURRENCIES', Currencies)
        }
      }

      if (state.visited === null) {
        commit('SET_VISITED', $moment())
      }
    }

    if (fid) {
      commit('SET_FID', fid)
      app.$cookies.set('fid', fid, {
        path: '/',
        maxAge: 28 * 24 * 60 * 60,
      })
    }
  },
  getCountries({ commit, state }) {
    if (state.countries.length !== 0) {
      return false
    }
    commit('SET_COUNTRIES', Countries)
  },
  getCurrencies({ commit, state }) {
    if (state.currencies.length !== 0) {
      return false
    }
    commit('SET_CURRENCIES', Currencies)
  },
  setFid({ commit }, fid) {
    commit('SET_FID', fid)
  },
  setUnit({ commit }, unit) {
    commit('SET_UNIT', unit)
  },
  setPriceDisplay({ commit }, priceDisplay) {
    commit('SET_PRICE_DISPLAY', priceDisplay)
  },
  setVisited({ commit }, closed) {
    commit('SET_VISITED', closed)
  },
  setClosed({ commit }, closed) {
    commit('SET_CLOSED', closed)
  },
  setReferral({ commit }, referral) {
    commit('SET_REFERRAL', referral)
  },
  setCountry({ commit }, country) {
    commit('SET_COUNTRY', country)
  },
  setCurrency({ commit }, currency) {
    commit('SET_CURRENCY', currency)
  },
  addSearch({ commit }, item) {
    commit('ADD_SEARCH', item)
  },
  removeSearch({ commit }, item) {
    commit('REMOVE_SEARCH', item)
  },
  clearSearches({ commit }) {
    commit('CLEAR_SEARCHES')
  },
  addFavorite({ commit }, item) {
    commit('ADD_FAVORITE', item)
  },
  removeFavorite({ commit }, item) {
    commit('REMOVE_FAVORITE', item)
  },
  clearFavorites({ commit }) {
    commit('CLEAR_FAVORITES')
  },
}

export const mutations = {
  WHITE_LABEL(state, whiteLabel) {
    state.whiteLabel = whiteLabel
  },
  SET_FID(state, fid) {
    state.fid = fid
  },
  SET_UNIT(state, unit) {
    state.unit = unit
  },
  SET_PRICE_DISPLAY(state, priceDisplay) {
    state.priceDisplay = priceDisplay
  },
  SET_VISITED(state, visited) {
    state.visited = visited
  },
  SET_CLOSED(state, closed) {
    state.closed = closed
  },
  SET_REFERRAL(state, referral) {
    state.referral = referral
  },
  SET_COUNTRY(state, country) {
    state.country = country
  },
  SET_CURRENCY(state, currency) {
    state.currency = currency
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_CURRENCIES(state, currencies) {
    state.currencies = currencies
  },
  ADD_SEARCH(state, item) {
    const element = state.searches.findIndex(
      (i) => i.router.split('&session')[0] === item.router.split('&session')[0],
    )
    if (element !== -1) {
      state.searches.splice(element, 1)
    }
    state.searches.push(item)
    if (state.searches.length > 8) {
      state.searches.shift()
    }
  },
  REMOVE_SEARCH(state, item) {
    const index = state.searches.indexOf(item)

    if (index !== -1) {
      state.searches.splice(index, 1)
    }
  },
  CLEAR_SEARCHES(state) {
    state.searches = []
  },
  ADD_FAVORITE(state, item) {
    state.favorites.push(item)
  },
  REMOVE_FAVORITE(state, item) {
    state.favorites.splice(item, 1)
  },
  CLEAR_FAVORITES(state) {
    state.favorites = []
  },
}
